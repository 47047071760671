<template>
  <div class="DAV-workshop__trunk">
    <component-title color="green" @goPrev="goPrev" />
    <Spinner v-show="isLoading" :propsPartial="true" />
    <div class="DAV-blockClass">
      <div class="product-search-wrap">
        상품 검색
        <div class="search-wrap">
          <Dropdown
            :options="[
              {text: '상품명', value: 'name'},
              {text: '상품번호', value: 'id'}
            ]"
            :value="search.select"
            @updateTarget="v => (search.select = v)"
          />
          <div class="search-input-wrap">
            <div class="textfield-wrap">
              <TextField
                :placeholder="
                  search.select === 'name'
                    ? '상품명 2자 이상 입력'
                    : '전체 상품번호 입력'
                "
                :value="search.text"
                @input="v => (search.text = v)"
              />
              <div class="search-button" @click="actProductList('search')">
                검색
              </div>
            </div>
            <div v-if="search.errorMsg" class="error-msg">
              <img src="@/assets/images/common/ico_error.svg" />
              검색할 상품명을 2자 이상 입력하세요.
            </div>
          </div>
        </div>
        <div v-if="list !== null && list.length" class="info-msg">
          검색된 상품 정보
        </div>
      </div>
      <div v-if="list !== null && list.length" class="product-list-wrap">
        <div class="list-top">
          <label class="frmBlock__label">
            <input
              type="checkbox"
              @click="clickAllCheck"
              :checked="allChecked"
            />
            <span></span>
          </label>
          <div class="add-button" @click="addProduct">선택 추가</div>
        </div>
        <div class="list-wrap" v-for="(item, index) in list" :key="index">
          <label class="frmBlock__label">
            <input
              type="checkbox"
              @click="clickCheck(index)"
              :checked="item.checked"
              :disabled="item.disabled"
            />
            <span></span>
          </label>
          <div
            class="DAV-block__item"
            :class="{'DAV-block__item--disabled': item.disabled}"
          >
            <div class="DAV-block__bullet">
              <img
                v-if="item.imgUrls"
                class="DAV-block_img"
                :src="item.imgUrls"
              />
              <img v-else src="@/assets/images/common/icon_logo_grey.svg" />
              <span>컴포넌트 숨김</span>
            </div>
            <div class="DAV-block__info">
              <div class="DAV-block__info--no">
                {{ item.name }}
              </div>
              <ul class="DAV-block__info--text">
                <li class="no">{{ item.id }}</li>
                <li class="line"></li>
                <li :class="{sale: item.salesStatus === '판매중'}">
                  {{ item.salesStatus }}
                </li>
                <li class="line"></li>
                <li :class="{display: item.isDisplay === '전시'}">
                  {{ item.isDisplay }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="totalCount > list.length"
          class="more-button"
          @click="actProductList('page')"
        >
          더보기 +
        </div>
      </div>
      <div v-if="list !== null && !list.length" class="product-list-empty">
        <img src="@/assets/images/admin/icon_empty.svg" />
        <p>검색된 데이터가 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      search: {
        text: "",
        select: "name",
        errorMsg: false
      },
      list: null,
      isLoading: false,
      allChecked: false,
      addedList: [],
      page: 0,
      totalCount: 0
    };
  },
  computed: {
    ...ModuleEditor.mapGetters(["getPopup"])
  },
  mounted() {
    this.addedList = this.getPopup.value;
    this.actProductList();
  },
  methods: {
    // 상품 검색
    actProductList(type) {
      let perPage = 10;

      if (this.search.select === "name" && this.search.text.length === 1) {
        this.search.errorMsg = true;
        return;
      } else {
        this.search.errorMsg = false;
      }

      if (type === "page") {
        this.page += 1;
      } else {
        this.page = 1;

        if (!this.search.text.length) {
          perPage = 30;
        }
      }
      const params = {
        user_theme_id: this.$route.params.themeId,
        page: this.page,
        limit: perPage
      };

      if (this.search.select === "name" && this.search.text.length) {
        params.name = this.search.text;
      }

      if (this.search.select === "id" && this.search.text.length) {
        params.id = this.search.text;
      }

      this.isLoading = true;
      this.$store
        .dispatch("ModuleEditor/actProductList", params)
        .then(response => {
          console.log(response);
          if (type !== "page") {
            this.list = [];
          }
          this.allChecked = false;
          response.data.products.map(i => {
            const added = this.addedList.filter(e => e.id === i.id);

            this.list.push({
              id: i.id,
              enabled: true,
              imgUrls: i.imgUrls[0],
              name: i.name,
              salesStatus:
                i.salesStatus === "ON_SALLING"
                  ? "판매중"
                  : i.salesStatus === "UN_SALLING"
                  ? "판매중지"
                  : i.salesStatus === "SCHEDULED_SALLING"
                  ? "판매대기"
                  : i.salesStatus === "SCHEDULED_IN"
                  ? "판매진행"
                  : i.salesStatus === "SCHEDULED_END"
                  ? "판매종료"
                  : "품절",
              isDisplay: i.isDisplay === "Y" ? "전시" : "미전시",
              checked: false,
              disabled: added.length ? true : false
            });
          });
          this.isLoading = false;
          if (type !== "page") {
            if (this.list.length < 10 || !this.search.text.length) {
              this.totalCount = this.list.length;
            } else {
              this.actProductCount();
            }
          }
        });
    },
    // 상품 개수
    actProductCount() {
      const params = {
        user_theme_id: this.$route.params.themeId
      };

      if (this.search.select === "name" && this.search.text.length) {
        params.name = this.search.text;
      }

      if (this.search.select === "id" && this.search.text.length) {
        params.id = this.search.text;
      }

      this.isLoading = true;
      this.$store
        .dispatch("ModuleEditor/actProductCount", params)
        .then(response => {
          console.log(response);
          this.totalCount = response.data.productCount;
          this.isLoading = false;
        });
    },
    // 이전 리스트로 이동
    goPrev() {
      this.$store.commit("ModuleEditor/setPopup", {
        page: null
      });
    },
    // clickAllCheck
    clickAllCheck() {
      this.allChecked = !this.allChecked;
      if (this.allChecked) {
        this.list.map(i => {
          if (!i.disabled) i.checked = true;
        });
      } else {
        this.list.map(i => (i.checked = false));
      }
    },
    // clickCheck
    clickCheck(i) {
      this.list[i].checked = !this.list[i].checked;
      const unChecked = this.list.filter(
        i => !i.disabled && i.checked === false
      );
      if (!unChecked.length) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    // addProduct
    addProduct() {
      const checked = this.list.filter(i => i.checked);
      if (!checked.length) {
        this.setAlert({
          isVisible: true,
          message: `추가할 상품을 선택하세요.`
        });
        return;
      }

      if (
        checked.concat(this.addedList).length >
        this.getPopup.option.componentOption.listMaxLength
      ) {
        this.setAlert({
          isVisible: true,
          message: `상품은 ${this.getPopup.option.componentOption.listMaxLength}개 까지 추가할 수 있습니다.`
        });
        return;
      }

      this.list.map(i => {
        if (i.checked) {
          i.disabled = true;
          i.checked = false;
        }
      });
      this.addedList = checked.concat(this.addedList);
      this.$EventBus.$emit(
        `productUpdateValue${this.getPopup.option.id}`,
        this.addedList
      );
      this.setAlert({
        isVisible: true,
        message: `선택 상품이 추가되었습니다.`
      });
    },

    ...ModuleAlert.mapMutations(["setAlert"])
  },
  components: {
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue"),
    Spinner: () => import("@/components/basis/spinner.vue")
  }
};
</script>

<style lang="scss" scoped>
.product-search-wrap {
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 24px 16px;

  .info-msg {
    margin-top: 48px;
    margin-bottom: -8px;
  }

  .search-wrap {
    margin-top: 8px;
    display: flex;

    .frmBlock__dropdownField {
      width: 110px;
      margin-right: 8px;
    }

    .search-input-wrap {
      width: calc(100% - 118px);

      .textfield-wrap {
        display: flex;

        .frmBlock__textField {
          width: calc(100% - 64px);
        }
      }

      .search-button {
        width: 56px;
        height: 48px;
        line-height: 48px;
        background: #54c7a2;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
      }

      .error-msg {
        margin-top: 10px;
        color: #d14343;
        font-weight: 400;
        font-size: 12px;

        img {
          vertical-align: -3px;
          margin-right: 2px;
        }
      }
    }
  }
}

.product-list-wrap {
  padding: 16px 16px 24px;

  .list-top {
    border-bottom: 1px solid #b7b7b7;
    padding-bottom: 8px;
    display: flex;
    margin-bottom: 16px;

    .frmBlock__label {
      margin-top: 4px;
    }

    .add-button {
      margin-left: auto;
      width: 72px;
      height: 32px;
      line-height: 32px;
      background: #54c7a2;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .list-wrap {
    width: 100%;
    margin-top: 8px;
    display: flex;

    .DAV-block__item {
      margin-left: 10px;
      width: calc(100% - 42px);
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      height: 81px;
      border: 1px solid #dcdcdc;
      box-sizing: border-box;
      @include box;

      &--active {
        z-index: 1 !important;
        border: 0 !important;
        box-shadow: 0px 0px 8px #54c7a2 !important;

        & .DAV-block__supports--move {
          background-image: url($path + "ico_move_active.svg");
        }
      }
      &--disabled {
        .DAV-block__bullet span {
          display: block;
        }
      }
    }
    .DAV-block__bullet {
      flex-shrink: 0;
      position: relative;
      width: 64px;
      height: 64px;
      margin: 0 16px;
      background-color: #f0f0f0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.4);
        background-image: url($path + "icon_disabled.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 16px auto;
        text-indent: -9999px;
      }

      .DAV-block_img {
        width: 64px;
        height: 64px;
        border-radius: 12px;
      }
    }
    .DAV-block__info {
      flex-grow: 1;
      overflow: hidden;
      padding-right: 16px;

      &--no {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &--text {
        li {
          display: inline-block;
          color: #b7b7b7;
        }

        .line {
          width: 1px;
          height: 12px;
          background: #dcdcdc;
          margin: 0 7px;
        }

        .no {
          color: #8d8d8d;
        }

        .sale {
          color: #3366ff;
        }

        .display {
          color: #ed5076;
        }
      }
    }
  }

  .more-button {
    height: 48px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    color: #b7b7b7;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: 24px;
  }
}

.product-list-empty {
  text-align: center;

  img {
    width: 153px;
    padding-top: 83px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #309574;
    margin: 20px 0;
  }
}

/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-workshop__heading {
    display: none !important;
  }
}
</style>
