var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('component-title', {
    attrs: {
      "color": "green"
    },
    on: {
      "goPrev": _vm.goPrev
    }
  }), _c('Spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "propsPartial": true
    }
  }), _c('div', {
    staticClass: "DAV-blockClass"
  }, [_c('div', {
    staticClass: "product-search-wrap"
  }, [_vm._v(" 상품 검색 "), _c('div', {
    staticClass: "search-wrap"
  }, [_c('Dropdown', {
    attrs: {
      "options": [{
        text: '상품명',
        value: 'name'
      }, {
        text: '상품번호',
        value: 'id'
      }],
      "value": _vm.search.select
    },
    on: {
      "updateTarget": function updateTarget(v) {
        return _vm.search.select = v;
      }
    }
  }), _c('div', {
    staticClass: "search-input-wrap"
  }, [_c('div', {
    staticClass: "textfield-wrap"
  }, [_c('TextField', {
    attrs: {
      "placeholder": _vm.search.select === 'name' ? '상품명 2자 이상 입력' : '전체 상품번호 입력',
      "value": _vm.search.text
    },
    on: {
      "input": function input(v) {
        return _vm.search.text = v;
      }
    }
  }), _c('div', {
    staticClass: "search-button",
    on: {
      "click": function click($event) {
        return _vm.actProductList('search');
      }
    }
  }, [_vm._v(" 검색 ")])], 1), _vm.search.errorMsg ? _c('div', {
    staticClass: "error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_error.svg")
    }
  }), _vm._v(" 검색할 상품명을 2자 이상 입력하세요. ")]) : _vm._e()])], 1), _vm.list !== null && _vm.list.length ? _c('div', {
    staticClass: "info-msg"
  }, [_vm._v(" 검색된 상품 정보 ")]) : _vm._e()]), _vm.list !== null && _vm.list.length ? _c('div', {
    staticClass: "product-list-wrap"
  }, [_c('div', {
    staticClass: "list-top"
  }, [_c('label', {
    staticClass: "frmBlock__label"
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.allChecked
    },
    on: {
      "click": _vm.clickAllCheck
    }
  }), _c('span')]), _c('div', {
    staticClass: "add-button",
    on: {
      "click": _vm.addProduct
    }
  }, [_vm._v("선택 추가")])]), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "list-wrap"
    }, [_c('label', {
      staticClass: "frmBlock__label"
    }, [_c('input', {
      attrs: {
        "type": "checkbox",
        "disabled": item.disabled
      },
      domProps: {
        "checked": item.checked
      },
      on: {
        "click": function click($event) {
          return _vm.clickCheck(index);
        }
      }
    }), _c('span')]), _c('div', {
      staticClass: "DAV-block__item",
      class: {
        'DAV-block__item--disabled': item.disabled
      }
    }, [_c('div', {
      staticClass: "DAV-block__bullet"
    }, [item.imgUrls ? _c('img', {
      staticClass: "DAV-block_img",
      attrs: {
        "src": item.imgUrls
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/images/common/icon_logo_grey.svg")
      }
    }), _c('span', [_vm._v("컴포넌트 숨김")])]), _c('div', {
      staticClass: "DAV-block__info"
    }, [_c('div', {
      staticClass: "DAV-block__info--no"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('ul', {
      staticClass: "DAV-block__info--text"
    }, [_c('li', {
      staticClass: "no"
    }, [_vm._v(_vm._s(item.id))]), _c('li', {
      staticClass: "line"
    }), _c('li', {
      class: {
        sale: item.salesStatus === '판매중'
      }
    }, [_vm._v(" " + _vm._s(item.salesStatus) + " ")]), _c('li', {
      staticClass: "line"
    }), _c('li', {
      class: {
        display: item.isDisplay === '전시'
      }
    }, [_vm._v(" " + _vm._s(item.isDisplay) + " ")])])])])]);
  }), _vm.totalCount > _vm.list.length ? _c('div', {
    staticClass: "more-button",
    on: {
      "click": function click($event) {
        return _vm.actProductList('page');
      }
    }
  }, [_vm._v(" 더보기 + ")]) : _vm._e()], 2) : _vm._e(), _vm.list !== null && !_vm.list.length ? _c('div', {
    staticClass: "product-list-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_empty.svg")
    }
  }), _c('p', [_vm._v("검색된 데이터가 없습니다.")])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }